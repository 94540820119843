import { useCallback, useEffect, useState } from "react";
const useDialog = (defaultValues)=>{
    const [isOpen, setIsOpen] = useState(false);
    const DEFAULT_DIALOG_PROPS = {
        onClose: ()=>setIsOpen(false),
        open: isOpen
    };
    const [dialogProps, setDialogProps] = useState({
        ...DEFAULT_DIALOG_PROPS,
        ...defaultValues
    });
    useEffect(()=>{
        setDialogProps((prev)=>({
                ...prev,
                open: isOpen
            }));
    }, [
        isOpen
    ]);
    const close = ()=>{
        setIsOpen(false);
    };
    const onOk = async ()=>{
        try {
            var _dialogProps_onOk;
            await ((_dialogProps_onOk = dialogProps.onOk) === null || _dialogProps_onOk === void 0 ? void 0 : _dialogProps_onOk.call(dialogProps));
        } catch (error) {
            ;
        } finally{
            close();
        }
    };
    const onCancel = async ()=>{
        try {
            var _dialogProps_onCancel;
            await ((_dialogProps_onCancel = dialogProps.onCancel) === null || _dialogProps_onCancel === void 0 ? void 0 : _dialogProps_onCancel.call(dialogProps));
        } catch (error) {
            ;
        } finally{
            close();
        }
    };
    const modifyDialog = useCallback((modifyProps)=>{
        setDialogProps({
            ...DEFAULT_DIALOG_PROPS,
            ...modifyProps
        });
    }, []);
    const open = useCallback((updateData)=>{
        updateData && modifyDialog(updateData);
        setIsOpen(true);
    }, [
        modifyDialog
    ]);
    return {
        open,
        close,
        modifyDialog,
        isOpen,
        dialogProps: {
            ...dialogProps,
            onOk,
            onCancel
        }
    };
};
export default useDialog;
